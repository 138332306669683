.App {
  line-height: 1.3;
  text-align: center;

  /* background-image: url(https://www.dukehealth.org/sites/default/files/blog_post/thinkstockphotos-480125956.jpg); */
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height:100vh;
  background-attachment: fixed;
}
p{
  font-size: 20px;
}
li{
  font-size: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */


.nav_holder{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  height: 200px;
  /* background: rgba(232, 240, 254, .75); */
  transition: 'background', 5s, ease-in-out;
  justify-content: center;
  
}
.nav_holder > div > a, .nav_holder > div > span > a{
  display: flex;
  align-items: center;
}

.nav_holder > div > a :hover{
  background: none;
}

.nav_holder :hover{
/* background: rgba(255,255,255, .5); */
}
.active{
  transform: scale(1.2);
color: red;
}
.nav {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  position: absolute;
  bottom: 10px;
  height: 50px;
  right: 0px;
  width: 45vw;
  padding: 0 30px 0 0;
  align-items: center;
  z-index: 5;
}

.inner_nav_holder{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1100px;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  /* background: red; */
  position: relative;
}
.logo {
  height: 20vh;
  position: absolute;
  left: 0;
  bottom: 18px;
  width: 70vw;
  max-width: 515px;
  max-height: 156px;
}
.allday{
  right: 0;
  position: absolute;
  top: 80px;
  height: 100px;
}
.phone_num{
  font-weight: bold;
    font-size: 21px;
    position: absolute;
    top: 0px;
    /* left: 394px; */
    /* right: 260px; */
    font-weight: bold;
    color: #2a1f7e;
}
.two{
  color: black;
  right: 0;
  font-size: 18px;
  text-align: end;
}

.nav2{
  font-weight: bold;
  display: none;
  justify-content: end;
  position:absolute;
  top: 0;
  /* height: ; */
  /* width: 100vw; */
  align-items: center;
  z-index: 5;

}


.nav > a, .nav > span > a{
  margin: 0 10px;
  font-size: 18px;
  text-decoration: none;

}

.photo_banner_holder{
  height: 50vw;
  min-height: 300px;
  max-height: 750px;
}
.photo_holder{
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation-name: imagesChanger;
  animation-duration: 15s; */
  transition: 2.5s ease-in-out background-image;
}
.photo_banner_text{

}
.photo_banner_text > a {
  height: 30vh;
  max-height: 85px;
  width: 30vw;
  min-width: 300px;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  font-size: clamp(20px, 4vw, 27px);
  font-weight: bold;
  background: navy;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo_banner1{
  height: 100%;
  width: 100%;
  background: darkgray;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/b/bc/Cervical_Collar_Emergency.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* animation-name: imagesChanger; */
  /* animation-duration: 15s; */
  transition: 15s ease-in-out 'background-image';
}
.photo_banner2{
  height: 100%;
  width: 100%;
  background-image: url('https://images.pexels.com/photos/163016/crash-test-collision-60-km-h-distraction-163016.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 15s ease-in-out 'background-image';
  /* animation-name: imagesChanger;
  animation-duration: 15s; */
}
.photo_banner3{
  height: 100%;
  width: 100%;
  /* background-color: red; */
  background-image: url('https://i.imgur.com/1hrHQWY.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 15s ease-in-out 'background-image';
  /* animation-name: imagesChanger;
  animation-duration: 15s; */
  
}

@keyframes imagesChanger {
  0%{
    opacity: 0.0;
  }
  1%{
    opacity: 0.5
  }
  2%{
    opacity:1.0;
  }
  
  98%{
    opacity:1.0;
  }
  99%{
    opacity: 0.5
  }
  100%{
    opacity: 0.0;
  }
}

.changing_effect{
  display: none;
}

.homepage{
  /* background: red; */
  /* background-image: url(https://www.dukehealth.org/sites/default/files/blog_post/thinkstockphotos-480125956.jpg);
  background-size: cover;
  width: 100vw;
  height:100vh;
  position:fixed; */
  top: 0;
}

.profile_img{
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  border-radius: 25px;
}

.doctor_info{
  display: flex;
  flex-wrap: wrap;
  padding: 75px;
  background: rgba(232, 240, 254, .75);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  margin: 0 75px;
  margin-top: 20px;
}
.doc_text > h1{
  font-size: 2.7em;
  margin-top: 0;

}
.doc_text{
  padding: 0 50px;
  text-align: left;
  color: #2a2179;
  /* text-shadow: 2px 2px black; */
  min-width: 300px;
  width: 50%;

}



a{
  transition: transform ease-out .15s;
  color: #2a2179;
}

a:active{
  transform: scale(1.2);
  color: #2a2179;
}

.staff_dropdown{
  background: white;
  position: absolute;
  width: 200px;
  flex-direction: column;
  display: none;
  text-align: left;
  text-decoration: none;
}
.staff_dropdown > a{
  text-decoration: none;
  padding: 10px 10px
}
.form-holder-holder{
width: 100vw;
display: flex;
justify-content: center;
}

.form-holder{
  min-width: 300px;
  width: 80vw;
  display: flex;
  flex-direction: column;
}
.form-holder > form{
  display: flex;
  flex-wrap: wrap;
}
.form-holder > form > input{
width: 100%;
height: 30px;
background: rgba(232, 240, 254, 1);
border: 1px solid black;
margin-bottom: 10px;
color: black;
padding-left: 10px;


}


.form-holder > form > input::placeholder {
  color: black;
  /* font-weight: bold; */
  opacity: 1;
}

.mNav_holder{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(232, 240, 254, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  z-index: 30;
  /* display: none; */
  /* margin: 20px 0; */

}




.delete{
  /* display: none  !important; */
}
.service_holder{
  width: 27vw;
  background: rgba(232, 240, 254, .75);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  min-width: 325px;
  min-height: 600px;
  height: fit-content;
  padding:20px;
  color: #2a2179;
  margin: 20px 10px;
}
.service_holder_holder{
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.service_holder > ul {
  text-align: left;
}
.service_holder > h4 {
  text-align: justify;

}
.service_holder > img {
  width: 95%;
  border-radius: 15px;
  height: 250px;
}

.extended{
  flex-direction: column;
  color: #2a2179;
  align-items: center;
  flex-wrap: wrap;
  
}
.extended > h1 {
  margin: 10px;
  transform: scale(1.3);

}
.openNav{
  display: flex !important;
  }

.alignFix{
  top: 42px;
}
.navDropDown > *{
padding: 10px 5px;
z-index: 100000000;
}
.specialNavHolder{
z-index: 999;
}

.extended > h3 {
  margin: 10px;
  transform: scale(1.3);
}
.extended > h4 {
  margin: 10px;
  transform: scale(1.3);
}

.buttons_holder{
  width: 25vw;
  min-width: 300px;
  min-height: 300px;
  height: 500px;
  margin: 5px;
  /* background: black; */
  min-width: 300px;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  border: 6px solid #2a2179;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.buttons_holder > img {
  width: 300px;
  height: 300px;
}
.buttons_holder > h2 {
  transform: scale(1.3);
}
#piFormHolder{
  justify-content: center;
}

@media screen and (max-width: 900px) {
  
  .nav{
    display: none;
  }
  .nav_holder{
    height: 100px;
  }
  .nav2{
    display: flex;
  }
  .doctor_info{
    padding: 15px;
    margin: 0;
    justify-content: center;
  }
  .form-holder{
    width: 100%;
  }
  .doc_text{
    padding: 0%;
    text-align: justify;
  }
  .doc_text > h1 {
    font-size: 2.1em;
    text-align: center;
  }
  
  .extended > h1 {
    margin: 10px;
    transform: scale(1.0);
  
  }
  .extended > h3 {
    margin: 10px;
    transform: scale(1.0);
  }
  .extended > h4 {
    margin: 10px;
    transform: scale(1.0);
  }
  .photo_banner_text > a {
    max-height: 75px;
  }
  }