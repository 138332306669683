#scroller{
    animation: scrollAnima;
    animation-duration: 240s;
    animation-timing-function: linear;
}

@keyframes scrollAnima {
    from {
        transform: translateX(100vw);
    }
    to{
        transform: translateX(-100%);
    }
}


.info_section_holder{
    width: 100vw;
    height: fit-content;
    justify-content: center;
    display: flex;
}

.info_section{
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr .5fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    'info1 form1';
}

.info1{
    grid-area: info1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    /* background: red; */
    text-align: left !important;
}
.info1 > ul > li{
    margin-bottom: 20px;
}
.info1 > h1{
    text-align: left;
}

.form1{
    grid-area: form1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #2a2179;
}
.goodCare_banner{
    width:100vw;
    height:125px;
    background: #2a2179;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold
}

.footer_holder{
    width: 100vw;
    min-height: 300px;
    height: fit-content;
    background: #2a2179;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gold;
    
}
.footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.footer_item{
    width: 27vw;
    min-width: 300px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.photo_banner1{
    /* filter: brightness(50%); */
}
.photo_banner_text {
    z-index: 5;
    /* width: 80vw; */
    max-width: 1100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: black 3px 3px 1px;
}
.photo_banner_text > h1 {
    font-size: clamp(30px, 5vw, 46px);
    margin-bottom: 0;
}
.photo_banner_text > h2{
    font-size:clamp(22px, 4vw, 45px);
}

.screen{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
}

.form1 {
justify-content: flex-start;
height: fit-content;
border-radius: 10px;
margin-top: 60px;
color: gold;
font-weight: bold;
font-size: 25px;
}

.form1 > form{
display: flex;
flex-direction: column;
width: 100%;
}

.form1 > form > * {
height: 35px;
margin:10px 0;
/* border: 2px solid white; */
border-radius: 5px;
font-family: 'Times New Roman', Times, serif;
}
.form1 > form > *::placeholder{
color: black;
font-size: 16px;
/* font-weight: bold; */
}

.about_office{
    width: 100vw;
    max-width: 1100px;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 20px 0;
    /* background: #2a2179; */
    align-items: flex-start;
    align-content: flex-start;
    color: gold;
    flex-wrap: wrap;
}


.ao_container{
    width: 50%;
    min-width: 300px;
    /* max-width: 300px; */
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* padding: 20px; */
    /* background: #2a2179; */
    align-items: center;
    color: black;
    
    
}
.ao_container > img{
    width:100%;
    max-width: 300px;
    padding-top: 100px;
}



.ao_text{
display: flex;
flex-direction: column;
/* width: 100%; */
/* max-width: none; */
/* padding: 10px; */
text-align: justify;
}
/* .ao_text > h1{
    text-align: start;
} */

.form_button{
    border: solid 2px white;
    width:50px;
    height: 50px;
    background: #2a2179;
    border-radius: 25px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    transition: height .5s ease-in-out, width .5s ease-in-out;
    animation-name: form_button;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    
    
}

@keyframes form_button{
    0%{
        transform: scale(1);
    }
    90%{
        transform: scale(1);
    }
    92%{
        transform: scale(1.5);
    }
    94%{
     transform: rotate(10deg);
    }
    96%{
     transform: rotate(-10deg);
    }
    98%{
     transform: rotate(10deg);
    }
    100%{
     transform: rotate(0deg);
    }
}



.form_button > img{
    transform: translateY(-1px);
}

.formButton_add{
    width: 250px;
    height: 500px;
    color:white;
    animation-name:none;
}
.formButton_add > ul > li > a{
    color: white;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    text-decoration: none;
}
.formButton_add > ul > li{
    font-size: 19px;
    text-align: left;
    animation-name: liss;
    animation-duration: 1s;
}


@keyframes liss{
0%{
    transform:  rotateY(90deg) scale(0.01);
}
100%{
    transform:  rotateY(0deg) scale(1);
}}





.formButton_add > ul > li:hover{
    color: gold;
    transition: color .5s ease-in-out;
}
.formButton_add > ul > li > a:hover{
    color: gold;
    transition: color .5s ease-in-out;
}
    


.footer_item > span > span > p{
    margin: 0;
    text-align: left;
    
}
.form_h{
    margin-bottom: 25px !important;
}

.contact_page_holder{
    width: 100vw;
    height: fit-content;
    max-width: 1100px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

}

.contact_page_holder > div{
    width: 50%;
    height: fit-content;

    min-width: 300px;
}

.c_form > form{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    /* background: #2a2179; */
    /* color: gold; */
    margin: 50px 0;
}
.c_form > form > span > *{
    width: 80%;
    height: 35px;
    margin: 10px 0;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    padding-left: 5px;
}
.c_form > form > span {
width: 100%;
display: flex;
justify-content: center;
flex-direction: column;
}

.c_form > form > span > textarea{
    height: 300px;
}
span > h3 {
    text-align: left;
    height: 20px !important;
    width: 80%;
}
.navDropDown{
    display: flex;
    flex-direction: column;
    display: none;
}
.m_nav_button{
    transform: scale(.75);
    width: 75px;
    height:75px;
    position: relative;
    top: 3px;
    right: 10px;
    /* background: white; */
    /* border: 3px solid #2a2179; */
    display: none;
    transition: height .5s ease-in-out, width .5s ease-in-out;
    z-index: 100;

}
.m_n_span{
    transform: rotateY(90deg) scale(.1);
    transition: transform 1s ease-in-out;
    color: rgba(0, 0, 0, 0);
}
.m_nav_button > a {
    transform: rotate(90deg) scale(.1);
    transition: 1s transform ease-in-out;
}

.line{
    background: #2a2179;
    height: 20px;
    width:75px;
    display: block;
    position: absolute;
    right: 0;
    transition: top 1s ease-in-out, transform 1s ease-in-out, height .5s ease-in-out, width .5s ease-in-out;
}

.bottom{
    position: absolute;
    top: 55px;
}

.middle{
    top: 27px;
    position: absolute;
}

.openMenu{
    top: 10px;
    height: 90vh;
    width: 90vw;
    z-index: 100;
    background: white;
    border: 3px solid #2a2179;
    transform: none;
    position: fixed;
    display: flex !important;
    justify-content: space-evenly;
    align-content: stretch;
    flex-direction: column;
    align-items: center;
}

.m_nav_button > a {
    display: none !important;
    transform: rotateY('90deg') scale(.5);
}
.m_nav_button > .m_n_span > a{
color: rgba(0, 0, 0, 0);
} 

.openMenu > .line {
    height: 10px;
    width: 55px;
    
}
.openMenu > .m_n_span {
    transform: rotateY(0deg) !important;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* color: rgba(0, 0, 0, 1); */
}

.openMenu > .m_n_span > a {
    font-weight: bold;
    text-decoration: none;
    display: flex !important;
    transform: rotateY(0deg);
    justify-content: center;
    /* animation-name: 'menuOptions';
    animation-duration: 2s; */
    color: rgba(42 ,33, 121, 1);
    
}

@keyframes menuOptions {
    0%{
        transform: rotateY('90deg') scale(.5);
    }
    100%{
        transform: rotateY('0deg') scale(1);
    }
}

.twotwo{
    max-width: 1100px;
    width: 80%;
}

.location_cell{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 2px solid black;
    margin-bottom: 10px;

}
.location_cell > div > img{
    width: 275px;
    height: 275px;
    object-fit: cover;
}
.location_cell > div:nth-child(2){
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
}

p{
    line-height: 1.5;
}




















@media screen and (max-width: 1100px){
    .info_section{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 810px;
        grid-template-areas:
        'info1'
        'form1';
    }
    .info1{
        justify-content: flex;
        align-items: center;
    }
    .form1{
        justify-content: flex-start;
        align-items: center;
    }
    .footer_item{
        width: 90vw;
        min-width: 300px;
        height: fit-content;
    }
    .phone_num{
        display: none;
    }
    .allday{
        display: none;
    }
    .nav{
        display: none;
    }
    .nav_holder{
        height: 100px;
    }
    .about_office_reverse{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .form_h{
        font-size: clamp(35px, 5vw, 55px);
    }
    .footer_holder{
        margin-top: 150px;
    }
    .c_form > form > span > *{
        width: 95%;
    }
    .m_nav_button{
        display:block;
    }
    .logo{
        top:7px;
        max-width: 337px;
        max-height:88px;
    }

    #twotwo{
        max-width: none !important;
        width: 100%;
    }
    .photo_banner_text{
        justify-content: center;
    }
}
@media screen and (min-width:1101px){
    .form_h{
        font-size: 36px;
        margin-bottom: 10px !important;
    }
    
}
@media screen and (max-width:654px){
    .location_cell{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 2px solid black;
        margin-bottom: 10px;
        justify-content: center;
        text-align: center;
    
    }
    .location_cell > div > img{
        width: 275px;
        height: 275px;
        object-fit: cover;
    }
    .location_cell > div:nth-child(2){
        padding: 20px;
        width: 300px;
        display: flex;
        flex-direction: column;
    }
}